body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'ZenMaruGothic';
  src: url('./app/font/ZenKakuGothicAntique-Regular.ttf') format('woff2');
  src: url('./app/font/ZenKakuGothicAntique-Bold.ttf') format('woff2');
}

@font-face {
  font-family: 'ZenMaruGothic-light';
  src: url('app/font/ZenKakuGothicAntique-Regular.ttf');
}

@font-face {
  font-family: 'ZenMaruGothic-normal';
  src: url('./app/font/ZenKakuGothicAntique-Regular.ttf') format('woff2');
}

@font-face {
  font-family: 'SourceHanSansSC-VF';
  src: url('./app/font/SourceHanSansSC-VF.otf');
}

@primary-color: #234DA6;