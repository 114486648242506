.companyBg {
  background-image: url(../../asset/company_bg.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.content {
  p {
    margin-block-start: 0;
  }
}

@primary-color: #234DA6;