.desc {
  overflow: hidden;
  color: var(--Grey-Grey-2, #4d4d4d);
  text-overflow: ellipsis;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-top: 8px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
}

.newsWrapper {
  width: 1202px;
  margin: 0 auto;
  .ant-tabs-nav {
    justify-content: center;
  }

  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: none;
  }

  .ant-tabs-nav-wrap {
    flex: none !important;
  }
}

.newsWrapperMobile {
  margin: 0 30px;
  .ant-tabs-nav {
    justify-content: center;
    font-size: 12px;
  }

  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: none;
  }

  .ant-tabs-nav-wrap {
    flex: none !important;
  }
}

@primary-color: #234DA6;