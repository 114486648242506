.customer-act {
  figure {
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0;
    margin-inline-end: 0;
  }

  p {
    font-family: 'ZenMaruGothic-light';
  }
}

@primary-color: #234DA6;