.video-container {
  position: relative;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url('../../asset/play.svg') no-repeat;
  border: none;
  cursor: pointer; /* Height of your play icon image */
  background-size: cover;
}

/* 隐藏原生的播放控件 */
// video::-webkit-media-controls {
//   display: none !important;
// }

@primary-color: #234DA6;