.headerBlock {
  background-image: url(../../asset/background.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 800px;
}

.otherBlock {
  height: auto;
}

.select {
  .ant-select-selection-item {
    color: #234da6;
    font-weight: 700;
  }
}

.select-mobile {
  .ant-select-selection-item {
    color: #234da6;
    font-weight: 700;
  }
  .ant-select-selector {
    padding: 0 !important;
  }
}

.customSide {
  .ant-drawer-header {
    display: none;
  }
}

@primary-color: #234DA6;