.title {
  color: #234da6;
  font-size: 80px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 7.2px;
}

.titleTips {
  color: #01a1d5;
  font-size: 52px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 7.54px;
}

.freeTrial {
  width: 1200px;
  height: 270px;
  display: flex;
  border-radius: 16px;
  border: 2px solid #8eacea;
  background: #fff;
  box-shadow: 0px 10px 0px 0px #bbdcf6;
  margin-left: calc(50% - 600px);
  margin-top: 160px;

  .spanTitle {
    color: #234da6;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 181%; /* 86.88px */
    letter-spacing: 6.96px;
  }
}

.freeTrialMobile {
  // width: 330px;
  height: 124px;
  border-radius: 8px;
  border: 0.562px solid #8eacea;
  background: #fff;
  box-shadow: 0px 2.812px 0px 0px #bbdcf6;
  margin: 0 32px;
  margin-top: 54px;
  padding: 18px 16px;
  box-sizing: border-box;

  .spanTitle {
    color: #234da6;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 86.88px */
  }
}

.btnStyle {
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.ul {
  color: #7e6f6d;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  li {
    text-indent: -6px;
  }
}

.blockTitle {
  color: #234da6;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 5.8px;
}

.blockChildTitle {
  color: #234da6;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 4.64px;
  text-align: center;
  background-image: linear-gradient(
    to bottom,
    transparent 28px,
    #fee8c3 0,
    #fee8c3
  );
}

.blockChildTitleMobile {
  color: #234da6;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.7px;
  text-align: center;
  background-image: linear-gradient(
    to bottom,
    transparent 14px,
    #fee8c3 0,
    #fee8c3
  );
}

.videoBlock {
  margin: 0 auto;
  width: 1200px;
  height: 658px;
  flex-shrink: 0;
  margin-top: 64px;
  border-radius: 16px;
  border: 16px solid #8cb0ff;
}

.fakeImg {
  background-image: url(../../asset/tool.svg);
  height: 658px;
  margin: 0 auto;
  width: 1200px;
  margin-top: 64px;
}

.textOverflow {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

@primary-color: #234DA6;